import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { deleteDocument, firestore } from '../../lib/firebase.prod';
import { Divider } from '@mantine/core';
import {
  BrandFacebook,
  BrandInstagram,
  BrandTwitter,
  BrandYoutube,
  WorldWww,
  Trash,
  Eye,
  Edit,
} from 'tabler-icons-react';
import { format } from 'date-fns';

import BioSection from './BioSection';
import { removeDashes, truncateString } from '../../helpers/util-fns';
import { useAuthListener, useUser } from '../../hooks';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';

const Profile = () => {
  const { user: authUser } = useAuthListener();
  let { slug } = useParams();
  const { user } = useUser(slug);
  const [muvents, setMuvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deletingID, setDeletingID] = useState(null);

  const userMuventsCall = query(
    collection(firestore, 'muvents'),
    orderBy('date', 'desc'),
    where('ownerId', '==', slug),
    limit(40),
  );

  useEffect(() => {
    if (userMuventsCall) {
      setLoading(true);
      getDocs(userMuventsCall).then(snapshot => {
        const firstMuvents = snapshot.docs.map(contentObj => ({
          ...contentObj.data(),
          docId: contentObj.id,
        }));
        setMuvents(firstMuvents);
        setLoading(false);
      });
    }
  }, [slug]);

  async function handleDeleteMuvent(evt, muvent) {
    const { docId, photoURL, title, category } = muvent;
    evt.preventDefault();
    setDeletingID(muvent.docId);

    notifications.show({
      id: 'delete-muvent',
      withCloseButton: true,
      autoClose: 5000,
      title: `Successfully deleted ${title}`,
      message: `Your ${category} was successfully deleted!`,
      color: 'teal',
      loading: false,
      icon: <IconCheck size="1rem" />,
    });

    try {
      await deleteDocument({ collection: 'muvents', docID: docId, photoURL });
      getDocs(userMuventsCall).then(snapshot => {
        const firstMuvents = snapshot.docs.map(contentObj => ({
          ...contentObj.data(),
          docId: contentObj.id,
        }));
        setMuvents(firstMuvents);
      });

      notifications.show({
        id: 'delete-muvent',
        withCloseButton: true,
        autoClose: 5000,
        title: `Successfully deleted ${title}`,
        message: `Your ${category} was successfully deleted!`,
        color: 'teal',
        loading: false,
        icon: <IconCheck size="1rem" />,
      });
      setDeletingID(null);
    } catch (error) {
      setDeletingID(null);
    }
  }

  function UserMuvents() {
    return (
      <div className="p-6 items-center sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
        {muvents?.map(muvent => {
          const viewMuventLink = `/muvents/${muvent.docId}`;
          const editMuventLink = `/muvents/${muvent.docId}/edit`;
          return (
            <figure
              className="flex bg-gray-50 rounded-xl hover:shadow-md hover:shadow-purple-200/40 transition-colors m-6 justify-between py-2"
              key={muvent.docId}
            >
              <div className="text-center md:text-left flex items-center">
                <Link to={{ pathname: viewMuventLink, state: muvent }} key={viewMuventLink}>
                  <img
                    className="w-20 h-20 object-cover bg-center rounded mx-2 cursor-pointer"
                    src={muvent.photoURL}
                    alt={muvent?.slug}
                  ></img>
                </Link>
                <div className="flex flex-col pl-4">
                  <blockquote>
                    <p className="text-lg sm:text-xs xs:text-sm font-medium">
                      {truncateString(removeDashes(muvent?.slug), 80)}
                    </p>
                  </blockquote>
                  <figcaption className="font-medium">
                    {muvent?.date ? (
                      <div className="text-slate-700">
                        {format(muvent?.date?.toDate() || new Date(), 'MMM d, yyyy')}
                      </div>
                    ) : null}
                  </figcaption>
                </div>
              </div>
              <div className="actions px-2 flex flex-col justify-center gap-4">
                {deletingID == muvent.docId
                  ? '...'
                  : authUser &&
                    authUser?.uid == muvent.ownerId && (
                      <Trash
                        size={20}
                        strokeWidth={2}
                        color={'black'}
                        className="cursor-pointer"
                        onClick={evt => handleDeleteMuvent(evt, muvent)}
                      />
                    )}
                <Link to={{ pathname: viewMuventLink, state: muvent }} key={viewMuventLink}>
                  <Eye size={20} strokeWidth={2} color={'black'} className="cursor-pointer" />
                </Link>
                {authUser && authUser?.uid == muvent.ownerId ? (
                  <Link to={{ pathname: editMuventLink, state: muvent }} key={editMuventLink}>
                    <Edit size={20} strokeWidth={2} color={'black'} className="cursor-pointer" />
                  </Link>
                ) : null}
              </div>
            </figure>
          );
        })}
      </div>
    );
  }

  function UserBioSection() {
    return (
      <>
        {authUser?.uid !== user.id && !user?.bio ? null : (
          <div className="flex flex-col justify-center">
            <h1 className="text-purple-400">
              About
              <span className="px-2 absolute">
                {authUser?.uid === user.id ? <BioSection user={user} authUser={authUser} /> : null}
              </span>
            </h1>
            <Divider my="xs" label="Bio" labelPosition="center" />
          </div>
        )}
        <div className="p-10 flex justify-center md:w-1/2 lg:w-1/2 xl:w-1/2">
          {user?.bio ? <p className="">{user?.bio}</p> : null}
          {authUser?.uid === user.id && !user?.bio && <p>Add your bio.</p>}
        </div>
      </>
    );
  }

  function UserMuventsSection() {
    return muvents.length && !loading ? (
      <>
        <div className="mt-14 mb-14 flex flex-col w-full lg:w-1/2 xl:w-1/2"></div>
        <div className="flex flex-col justify-center">
          <h1 className="text-purple-400">Dance events</h1>
          <Divider my="xs" label="Muvents" labelPosition="center" />
        </div>
        <UserMuvents />
      </>
    ) : null;
  }

  function MediaLinksSection() {
    if (!user?.website && !user?.instagram && !user?.facebook && !user?.youtube && !user?.twitter) return null;
    return (
      <>
        <div className="mt-14 mb-14 flex flex-col"></div>
        <div className="flex flex-col justify-center">
          <h1 className="text-purple-400">
            Media Links
            <span className="px-2 absolute">
              {authUser?.uid === user.id ? <BioSection user={user} authUser={authUser} /> : null}
            </span>
          </h1>
          <Divider my="xs" label="Social media links" labelPosition="center" />
        </div>

        <div className="flex flex-col justify-center">
          {user?.website ? (
            <a href={user?.website} target="_blank" rel="noopener noreferrer">
              <div className="flex justify-center items-center gap-2">
                <WorldWww size={16} />
                {user?.website}
              </div>
            </a>
          ) : null}

          {user?.instagram ? (
            <a href={user?.instagram} target="_blank" rel="noopener noreferrer">
              <div className="flex justify-center items-center gap-2">
                <BrandInstagram size={16} />
                {user?.instagram}
              </div>
            </a>
          ) : null}

          {user?.facebook ? (
            <a href={user?.facebook} target="_blank" rel="noopener noreferrer">
              <div className="flex justify-center items-center gap-2">
                <BrandFacebook size={16} />
                {user?.facebook}
              </div>
            </a>
          ) : null}

          {user?.youtube ? (
            <a href={user?.youtube} target="_blank" rel="noopener noreferrer">
              <div className="flex justify-center items-center gap-2">
                <BrandYoutube size={16} />
                {user?.youtube}
              </div>
            </a>
          ) : null}

          {user?.twitter ? (
            <a href={user?.twitter} target="_blank" rel="noopener noreferrer">
              <div className="flex justify-center items-center gap-2">
                <BrandTwitter size={16} />
                {user?.twitter}
              </div>
            </a>
          ) : null}
        </div>
      </>
    );
  }

  if (!user || loading) return <div className="flex justify-center m-20 h-screen">...</div>;

  return (
    <>
      <div className="mt-20 bg-center flex min-h-full justify-center">
        <div className="w-full lg:w-1/2 xl:w-1/2 flex flex-col items-center justify-center relative mt-20 mb-10 p-10 rounded-md bg-gradient-to-b from-teal-50 to-slate-50">
          <div className="absolute right-2">
            {user?.instagram ? (
              <a href={user?.instagram} target="_blank" rel="noopener noreferrer" className="hover:text-purple-400 m-2">
                <BrandInstagram size={20} />
              </a>
            ) : null}
            {user?.facebook ? (
              <a href={user?.facebook} target="_blank" rel="noopener noreferrer" className="hover:text-purple-400 m-2">
                <BrandFacebook size={20} />
              </a>
            ) : null}

            {user?.twitter ? (
              <a href={user?.twitter} target="_blank" rel="noopener noreferrer" className="hover:text-purple-400 m-2">
                <BrandTwitter size={20} />
              </a>
            ) : null}
            {user?.youtube ? (
              <a href={user?.youtube} target="_blank" rel="noopener noreferrer" className="hover:text-purple-400 m-2">
                <BrandYoutube size={20} />
              </a>
            ) : null}
          </div>
          <img
            className="rounded-full bg-cover w-48 h-48 bg-center border-black md:visible lg:visible xl:visible"
            src={user.photoURL}
            alt={user?.name}
          />
          <h1 className="text-gray-900 font-ultra p-2 text-center">{user?.name}</h1>

          <div className="mt-6 rounded flex flex-col bg-gradient-to-b from-teal-100/20 to-slate-100/80 divide-y divide-slate-200/70">
            {muvents?.length > 0 ? (
              <div className="text-center px-10 py-6">
                <p>{muvents.length}</p>
                <p className="text-xs">MUVENTS</p>
              </div>
            ) : null}

            <div className="text-center px-10 py-[4px]">
              <small>
                <em>Joined </em>
                <span>
                  {new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(user?.created_at)}
                </span>
              </small>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center flex-col mt-10 mb-10 min-h-[50vh]">
        <UserBioSection />
        <MediaLinksSection />
        <UserMuventsSection />
      </div>
    </>
  );
};

export default Profile;

import React from 'react';
import { collection, getDocs, query, limit, orderBy, desc } from 'firebase/firestore';
import { firestore } from '../lib/firebase.prod';
import { useState, useEffect } from 'react';

const AppUpdates = () => {
  const [updates, setUpdates] = useState([]);

  const updatesCall = query(
    collection(firestore, 'email_notif_umuv_updates'),
    orderBy('created_at', 'desc'),
    limit(200),
  );

  useEffect(() => {
    getDocs(updatesCall).then(snapshot => {
      const firstFacts = snapshot.docs.map(contentObj => ({
        ...contentObj.data(),
        docId: contentObj.id,
      }));

      setUpdates(firstFacts);
    });
  }, [updatesCall]);

  return (
    <div className="flex justify-center mt-40">
      <div className="p-10 flex gap-4 flex-col w-2/2 md:w-1/2">
        {updates.map(({ update_1_title, update_1_description, update_tag_version }) => {
          return (
            <div key={update_1_title} className="p-4 bg-white rounded shadow-lg text-slate-800 flex flex-col gap-4">
              <p>{update_1_title}</p>
              <div className="">{update_1_description}</div>
              <small className="font-bold italic">{update_tag_version}</small>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AppUpdates;

import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AuthWrapper, FormError, Spinner } from './styles';
import { resetPassword } from '../lib/firebase.prod';
import { Button } from '@mantine/core';
import { IconCheck, IconLockOpen } from '@tabler/icons-react';
import { Link, useHistory } from 'react-router-dom';
import { SIGN_IN } from '../constants/routes';
import { notifications } from '@mantine/notifications';

const ResetPassword = () => {
  const [resetPasswordError, setResetPasswordError] = useState(null);

  let history = useHistory();

  const handleSubmit = async ({ email }, { setSubmitting }) => {
    try {
      await resetPassword(email);
      notifications.show({
        id: 'reset-pass-notif',
        withCloseButton: true,
        autoClose: 6000,
        title: `A link to reset your password has been sent to ${email}`,
        message: `Don't forget to check your spam folder 😀`,
        color: 'teal',
        loading: false,
        icon: <IconCheck size="1rem" />,
      });

      history.push(SIGN_IN);
    } catch (error) {
      setResetPasswordError(error.message);
    }
    setSubmitting(false);
  };

  const loaderProps = { loading: true, size: 80 };

  return (
    <AuthWrapper className="h-screen bg-gradient-to-r from-purple-50 via-purple-100 to-teak-50 xs:p-2">
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={Yup.object({
          email: Yup.string().email().required('Required'),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="lg:w-2/3 md:w2/3 sm:w-5/6 xl:w-2/4 xs:w-full border-[3px] border-slate-800/10 rounded-lg">
            <h1 className="text-2xl mb-5 mt-5 text-slate-100">Reset your password</h1>
            <Field
              id="email"
              name="email"
              placeholder="Enter your email address"
              className="form-control shadow appearance-none border rounded w-full py-4 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
            />
            <FormError>
              <ErrorMessage name="email" />
            </FormError>
            {resetPasswordError ? <FormError>{resetPasswordError}</FormError> : null}
            {isSubmitting ? (
              <Spinner>...</Spinner>
            ) : (
              <Button
                leftIcon={<IconLockOpen size={16} />}
                variant="light"
                color="indigo"
                type="submit"
                disabled={isSubmitting}
                className="w-full rounded-full text-slate-800 text-xl h-16 bg-white hover:text-slate-100 hover:bg-black"
              >
                RESET
              </Button>
            )}
            <div className="grid grid-cols-1">
              <div className="flex justify-end">
                <p className="py-2">
                  <Link to={SIGN_IN} className="text-slate-100 px-3 py-2 ml-2 rounded-full bg-slate-800 xs:my-1">
                    Sign In
                  </Link>{' '}
                </p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </AuthWrapper>
  );
};

export default ResetPassword;

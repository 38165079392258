import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FirebaseContext } from '../context/firebase';
import { Formik, Form } from 'formik';
import { PostMuventWrapperTwinMacro, Spinner } from './styles';
import { toDashes } from '../helpers/util-fns';
import { useAuthListener } from '../hooks';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { DatePickerInput } from '@mantine/dates';
import { Select, TextInput, Checkbox, Progress, NumberInput, Button, Textarea, Autocomplete } from '@mantine/core';
import {
  At,
  AddressBook,
  CurrencyEuro,
  Video,
  Folder,
  BluetoothConnected,
  Ticket,
  ArrowLeft,
  BrandFirebase,
  TextResize,
  Stack2,
  Writing,
  Calendar,
} from 'tabler-icons-react';

import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { countries } from '../helpers/generalData';
import CustomDivider from '../components/CustomDivider';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { firestore, storage } from '../lib/firebase.prod';

const EditMuvent = () => {
  let { slug } = useParams();
  let history = useHistory();
  const { user } = useAuthListener();
  const [muvent, setMuvent] = useState({});
  const { firebase } = useContext(FirebaseContext);

  const [file, setFile] = useState(null);
  const [imageName, setImageName] = useState('muventImg');
  const [existingImgURL, setExistingImgURL] = useState('');
  const [isCropConfirmed, setIsCropConfirmed] = useState(false);
  const [upImg, setUpImg] = useState();
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [yours, setYours] = useState(false);
  const [free, setFree] = useState(false);
  const [contactEmail, setContactEmail] = useState('');
  const [fee, setFee] = useState(0);
  const [addressOne, setAddressOne] = useState('');
  const [addressTwo, setAddressTwo] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [websiteLink, setWebsiteLink] = useState('');
  const [ticketLink, setTicketLink] = useState('');
  const [applicationFormLink, setApplicationFormLink] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [moreInfo, setMoreInfo] = useState('');
  const [uploadPhotoProgress, setUploadPhotoProgress] = useState(0);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const format = 'HH:mm';

  useEffect(() => {
    if (slug) {
      const muventRef = doc(firestore, 'muvents', slug);

      getDoc(muventRef)
        .then(docSnap => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            setMuvent(data);
            setTitle(data?.title ?? '');
            setCategory(data?.category ?? '');
            if (data?.date) setDate(data.date.toDate() ?? new Date());
            setDescription(data?.description ?? '');
            setYours(data?.yours ?? false);
            setFree(data?.free ?? false);
            setContactEmail(data?.contact_email ?? '');
            setCountry(data?.country ?? '');
            setCity(data?.city ?? '');
            setFee(data?.fee || 0);
            setAddressOne(data?.address_1 ?? '');
            setAddressTwo(data?.address_2 ?? '');
            setVideoLink(data?.video_link ?? '');
            setWebsiteLink(data?.website_link ?? '');
            setTicketLink(data?.ticket_link ?? '');
            setApplicationFormLink(data?.application_form_link ?? '');
            setMoreInfo(data?.more_info ?? '');
            setExistingImgURL(data?.photoURL ?? '');
            // setStartTime(dayjs(data?.start?.toDate()).format(format));
          }
        })
        .catch(_error => {
          notifications.show({
            id: 'login-notif',
            withCloseButton: true,
            autoClose: 6000,
            title: `Oops!`,
            message: `Something went wrong. Please try again later`,
            color: 'red',
            loading: false,
            icon: <IconCheck size="1rem" />,
          });
          history.push('/muvents');
        });
    }
  }, [slug, history]);

  function generatePhotoForUpload(canvas) {
    canvas.toBlob(blob => {
      setFile(blob);
      setIsCropConfirmed(true);
    });
  }

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setImageName(e.target.files[0].name);
      setIsCropConfirmed(false);
    }
  };

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
  }, [completedCrop]);

  const handleSubmit = async () => {
    const storageRef = ref(storage, 'images/' + imageName);
    const metadata = { contentType: 'image/jpeg' };

    let editedMuvent = {
      title: title,
      slug: toDashes(title),
      category,
      description,
      date,
      start: startTime,
      yours,
      fee,
      free,
      address_1: addressOne,
      address_2: addressTwo,
      video_link: videoLink,
      website_link: websiteLink,
      ticket_link: ticketLink,
      application_form_link: applicationFormLink,
      more_info: moreInfo,
      contact_email: contactEmail,
      country,
      city,
    };

    const showEditedMuventNotification = () => {
      notifications.show({
        id: 'login-notif',
        withCloseButton: true,
        autoClose: 6000,
        title: `Yay! You rock ${user.displayName}`,
        message: `Successfully edited ${title}`,
        color: 'teal',
        loading: false,
        icon: <IconCheck size="1rem" />,
      });
    };

    const location = {
      pathname: `/muvents/${slug}`,
      state: {
        title,
        description,
        slug,
        ownerId: user.uid,
        date,
        start: startTime,
        yours,
        fee,
        free,
        address_1: addressOne,
        address_2: addressTwo,
        video_link: videoLink,
        website_link: websiteLink,
        ticket_link: ticketLink,
        application_form_link: applicationFormLink,
        more_info: moreInfo,
        creator_name: user.displayName,
        country,
        city,
      },
    };

    if (file) {
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      uploadTask.on(
        'state_changed',
        snapshot => {
          let uploadProgressRaw = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          const uploadProgress = Number(uploadProgressRaw.toFixed());
          setUploadPhotoProgress(uploadProgress);
        },
        () => {},
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          location.state.photoURL = downloadURL;
          editedMuvent.photoURL = downloadURL;
          const muventRef = doc(firestore, 'muvents', slug);
          await updateDoc(muventRef, editedMuvent);
          showEditedMuventNotification();
          history.push(location);
        },
      );
    } else {
      const muventRef = doc(firestore, 'muvents', slug);
      const updated = await updateDoc(muventRef, editedMuvent);
      showEditedMuventNotification();
      history.push(location);
    }
  };

  function handleBack(evt) {
    evt.preventDefault();
    history.push(`/muvents/${slug}`);
  }

  const getCitiesData = useCallback(() => {
    return (
      countries.find(({ name }) => name === country.split(' ').slice(1).join(' '))?.cities?.map?.(({ name }) => name) ??
      []
    );
  }, [country]);

  const getCountriesData = () => {
    return countries.map(({ flag, name }) => `${flag} ${name}`);
  };

  return (
    <PostMuventWrapperTwinMacro>
      <Formik enableReinitialize initialValues={{}} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form className="lg:w-3/3 md:w-4/4 sm:w-full xl:w-4/6 bg-white flex justify-center my-10">
            <div className="flex justify-center mb-10 align-center">
              <label htmlFor="file"></label>
              {completedCrop && !completedCrop ? null : <input type="file" accept="image/*" onChange={onSelectFile} />}
            </div>
            {isCropConfirmed ? null : (
              <div className="react-crop">
                <ReactCrop
                  src={upImg}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={c => setCrop(c)}
                  onComplete={c => setCompletedCrop(c)}
                />
              </div>
            )}
            <div className="cropped-img mt-10">
              <canvas
                ref={previewCanvasRef}
                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                style={{
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0),
                }}
              />
            </div>
            <div className="confirm-edit-section">
              {crop && completedCrop && !isCropConfirmed ? (
                <button
                  type="button"
                  className="w-full confirm-crop rounded-full text-gray-900 text-xl h-16 bg-purple-300 hover:bg-gray-100 hover:text-purple-600 p-10"
                  onClick={() => {
                    generatePhotoForUpload(previewCanvasRef.current);
                  }}
                >
                  confirm
                </button>
              ) : null}
              {completedCrop ? (
                <button
                  type="button"
                  className="w-full confirm-crop rounded-full text-gray-900 text-xl h-16 bg-purple-300 hover:bg-gray-100 hover:text-purple-600 p-10"
                  onClick={() => {
                    setIsCropConfirmed(false);
                  }}
                >
                  edit
                </button>
              ) : null}
            </div>
            <div className="flex justify-center my-10">
              {existingImgURL && !completedCrop ? (
                <div className="flex gap-2">
                  <img
                    alt=" container-img"
                    src={existingImgURL}
                    style={{
                      height: '600px',
                      objectFit: 'cover',
                      width: '100%',
                    }}
                  ></img>
                </div>
              ) : (
                !completedCrop && (
                  <Spinner className="text-center justify-center">
                    {/* <SpinningOrbitLoader {...{ loading: true, size: 120 }} /> */}
                    <LdsRoller />
                  </Spinner>
                )
              )}
            </div>
            <div className="flex flex-col gap-2 md:flex-row md:justify-between md:gap-10">
              <TextInput
                placeholder="The title of the muvent"
                radius="xs"
                required
                value={title}
                icon={<TextResize size={16} />}
                onChange={event => setTitle(event.currentTarget.value)}
                className="w-full"
              />
              <Select
                placeholder="Pick a category"
                searchable
                nothingFound="No options"
                transition="pop-top-left"
                // transitionDuration={80}
                // transitionTimingFunction="ease"
                icon={<Stack2 size={16} />}
                data={[
                  'audition',
                  'workshop',
                  'openCall',
                  'scholarship',
                  'intensive',
                  'residency',
                  'festival',
                  'program',
                  'training',
                  'studies',
                  'internship',
                  'show',
                  'competition',
                  'other',
                ]}
                onChange={val => setCategory(val)}
                value={category}
                className="w-full"
              />
            </div>
            <div className="flex flex-col my-10">
              <Textarea
                icon={<Writing size={16} />}
                placeholder={`Write a short description for your ${category ? category : 'event'}`}
                autosize
                value={description}
                minRows={5}
                onChange={evt => setDescription(evt.currentTarget.value)}
              />
            </div>
            <div className="flex flex-col gap-6 md:flex-row md:justify-between md:gap-10 items-center">
              <DatePickerInput
                dropdownType="modal"
                placeholder={`Pick your ${category} date`}
                value={date}
                onChange={date => setDate(date)}
                icon={<Calendar size={16} />}
                className="w-full"
              />

              <TimePicker
                value={startTime ? dayjs(startTime) : dayjs(muvent?.start?.toDate())}
                format={format}
                onChange={time => {
                  if (time) {
                    setStartTime(time?.toDate());
                  }
                }}
                className="w-full text-center border-white rounded-full"
              />
            </div>

            <CustomDivider title="Contact & address" />
            <div className="flex flex-col gap-6 md:flex-row md:justify-between md:gap-10">
              <TextInput
                placeholder="Contact email"
                icon={<At size={14} />}
                value={contactEmail}
                onChange={event => setContactEmail(event.currentTarget.value)}
                className="w-full"
              />

              <NumberInput
                hideControls
                placeholder="Fee"
                icon={<CurrencyEuro size={14} />}
                precision={2}
                min={0}
                max={1000}
                value={fee}
                onChange={val => setFee(val)}
                className="w-full"
              />
            </div>

            <div className="flex flex-col gap-4 md:flex-row md:justify-between md:gap-10 my-6">
              <TextInput
                placeholder="Street and number"
                icon={<AddressBook size={14} />}
                value={addressOne}
                onChange={event => setAddressOne(event.currentTarget.value)}
                className="w-full"
              />
              <TextInput
                placeholder="Address Line 2"
                icon={<AddressBook size={14} />}
                value={addressTwo}
                onChange={event => setAddressTwo(event.currentTarget.value)}
                className="w-full"
              />
            </div>

            <div className="flex flex-col gap-4 md:flex-row md:justify-between md:gap-10 mt-4">
              <Autocomplete
                value={country}
                onChange={val => {
                  setCity('');
                  setCountry(val);
                }}
                placeholder="Country: Type the country to see options"
                data={getCountriesData()}
                className="w-full"
              />

              <Autocomplete
                value={city}
                onChange={setCity}
                placeholder={country?.length ? `City: Type the city to see options` : `Choose the country first`}
                data={getCitiesData()}
                className="w-full"
              />
            </div>

            <CustomDivider title="Links" />

            <div className="flex flex-col gap-1 md:flex-row md:justify-between md:gap-10">
              <TextInput
                placeholder="Video link"
                icon={<Video size={14} />}
                defaultValue={videoLink}
                onChange={event => setVideoLink(event.currentTarget.value)}
                className="w-full"
              />

              <TextInput
                placeholder="Website link"
                icon={<BluetoothConnected size={14} />}
                defaultValue={websiteLink}
                onChange={event => setWebsiteLink(event.currentTarget.value)}
                className="w-full"
              />
            </div>

            <div className="flex flex-col gap-1 md:flex-row md:justify-between md:gap-10 my-4">
              <TextInput
                placeholder="Ticket link"
                icon={<Ticket size={14} />}
                defaultValue={ticketLink}
                onChange={event => setTicketLink(event.currentTarget.value)}
                className="w-full"
              />

              <TextInput
                placeholder="Application form link"
                icon={<Folder size={14} />}
                defaultValue={muvent?.application_form_link}
                onChange={event => setApplicationFormLink(event.currentTarget.value)}
                className="w-full"
              />
            </div>

            <div className="mt-20"></div>

            <CustomDivider title="More Info" />
            <div className="flex flex-col gap-6 md:flex-row md:justify-between md:gap-10">
              <Checkbox
                label="Is this a FREE event?"
                radius="xs"
                onChange={evt => setFree(evt.currentTarget.checked)}
                checked={free}
                className="w-full"
              />

              <Checkbox
                label="Is this your event?"
                radius="xs"
                onChange={evt => setYours(evt.currentTarget.checked)}
                checked={yours}
                className="w-full"
              />
            </div>

            <div className="flex flex-col gap-6 md:flex-row md:justify-between md:gap-10 my-8">
              <Textarea
                placeholder="More Info"
                autosize
                minRows={5}
                onChange={evt => setMoreInfo(evt.target.value)}
                defaultValue={muvent?.more_info}
                className="w-full"
              />
            </div>

            <div className="mt-20"></div>

            {!!uploadPhotoProgress && <Progress value={uploadPhotoProgress} />}

            <div className="flex gap-2 flex-row md:justify-between md:gap-10">
              <Button
                leftIcon={<ArrowLeft size={30} />}
                variant="white"
                color="indigo"
                onClick={handleBack}
                disabled={isSubmitting}
                className="w-1/4 rounded-full text-xl h-16 bg-purple-300 text-white hover:bg-gray-100 hover:text-purple-600 text-center"
              ></Button>
              <Button
                leftIcon={<BrandFirebase size={14} />}
                variant="white"
                color="indigo"
                type="submit"
                disabled={isSubmitting}
                className="w-3/4 rounded-full text-white text-xl h-16 bg-purple-300 hover:bg-gray-100 hover:text-purple-600"
              >
                UPDATE
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </PostMuventWrapperTwinMacro>
  );
};

export default EditMuvent;

import { SearchWithAlgolia } from '../components/SearchWithAlgolia';

const Muvents = () => {
  return (
    <div className="bg-white h-full mt-20 w-full">
      <div className="relative flex justify-center text-center m-6 mt-10 mb-20">
        <SearchWithAlgolia />
      </div>
    </div>
  );
};

export default Muvents;

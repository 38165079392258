import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AuthWrapper, FormError } from './styles';
import { register } from '../lib/firebase.prod';
import { Button } from '@mantine/core';
import { LockOpen } from 'tabler-icons-react';
import { SIGN_IN } from '../constants/routes';
import { Link } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';

export default function Register() {
  const [firebaseError, setFirebaseError] = useState(null);

  const sleep = async ms => new Promise(resolve => setTimeout(resolve, ms));

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await sleep(2000);
    const { name, email, password } = values;
    try {
      await register(name, email, password);

      notifications.show({
        id: 'welcome-to-umuv-notif',
        withCloseButton: true,
        autoClose: 5000,
        title: `Welcome to UMUV ${name}`,
        message: `Happy you are joining us!`,
        color: 'teal',
        loading: false,
        icon: <IconCheck size="1rem" />,
      });
    } catch (error) {
      if (error?.message) {
        setFirebaseError(error?.message);
      }
    }
    setSubmitting(false);
  };

  return (
    <AuthWrapper className="h-screen bg-gradient-to-r from-purple-50 via-purple-100 to-teak-50 xs:p-2">
      <Formik
        initialValues={{ name: '', email: '', password: '' }}
        validationSchema={Yup.object({
          name: Yup.string().max(50, 'Must be 25 characters or less').required('Required'),
          email: Yup.string().max(40, 'Must be 40 characters or less').required('Required'),
          password: Yup.string().required('Required'),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <>
            {!isSubmitting && (
              <Form className="lg:w-2/3 md:w2/3 sm:w-5/6 xl:w-2/4 xs:w-full border-[3px] border-slate-800/10 rounded-lg">
                <h1 className="text-2xl mb-5 mt-5 text-white">SIGN UP</h1>
                <Field
                  name="name"
                  placeholder="Enter your name"
                  type="text"
                  className="form-control shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                />
                <FormError>
                  <ErrorMessage name="firstName" />
                </FormError>

                <Field
                  name="email"
                  type="email"
                  placeholder="Enter your email address"
                  className="form-control shadow appearance-none border rounded w-full py-4 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline mb-2"
                />
                <FormError>
                  <ErrorMessage name="email" />
                </FormError>

                <Field
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  className="form-control shadow appearance-none border rounded w-full py-4 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
                />
                <FormError>
                  <ErrorMessage name="password" />
                </FormError>

                {firebaseError ? (
                  <>
                    <p>{firebaseError}</p>
                    <div className="mb-2"></div>
                  </>
                ) : null}
                <Button
                  leftIcon={<LockOpen size={14} />}
                  variant="light"
                  color="indigo"
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full rounded-full text-slate-800 text-xl h-16 bg-white hover:text-slate-100 hover:bg-black"
                >
                  SUBMIT
                </Button>
                <div className="grid grid-cols-1">
                  <div className="flex justify-end">
                    <p className="py-2">
                      <Link
                        to={SIGN_IN}
                        className="text-slate-100 px-3 py-1 ml-2 rounded-full bg-slate-900 border-[1px] hover:border hover:border-purple-300 xs:my-1"
                      >
                        Sign In
                      </Link>{' '}
                    </p>
                  </div>
                </div>
              </Form>
            )}
          </>
        )}
      </Formik>
    </AuthWrapper>
  );
}

import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AuthWrapper, FormError, Spinner } from './styles';
import { login } from '../lib/firebase.prod';
import { Button } from '@mantine/core';
import { Login } from 'tabler-icons-react';
import { Link } from 'react-router-dom';
import { RESET_PATH, SIGN_UP } from '../constants/routes';

const SignIn = () => {
  const [loginError, setLoginError] = useState(null);

  const handleSubmit = async ({ email, password }, { setSubmitting }) => {
    try {
      await login(email, password);
    } catch (error) {
      setLoginError(error.message);
    }
    setSubmitting(false);
  };

  const loaderProps = { loading: true, size: 80 };

  return (
    // <AuthWrapper className="h-screen bg-slate-100">
    <AuthWrapper className="h-screen bg-gradient-to-r from-purple-50 via-purple-100 to-teak-50 xs:p-2">
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={Yup.object({
          email: Yup.string().email().required('Required'),
          password: Yup.string().required('Required'),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="lg:w-2/3 md:w2/3 sm:w-5/6 xl:w-2/4 xs:w-full border-[3px] border-slate-800/10 rounded-lg">
            <h1 className="text-2xl mb-5 mt-5 text-slate-100">LOG IN</h1>
            <Field
              id="email"
              name="email"
              placeholder="Enter your email address"
              className="form-control rounded-full w-full py-4 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
            />
            <FormError>
              <ErrorMessage name="email" />
            </FormError>
            <div className="mb-1"></div>
            <Field
              id="password"
              name="password"
              placeholder="Password"
              type="password"
              className="shadow appearance-none border w-full py-4 px-3 text-gray-800 leading-tight focus:outline-none focus:shadow-outline"
            />

            <FormError>
              <ErrorMessage name="password" />
            </FormError>

            {loginError ? <FormError>{loginError}</FormError> : null}

            {isSubmitting ? (
              <Spinner>...</Spinner>
            ) : (
              <Button
                leftIcon={<Login size={22} />}
                type="submit"
                disabled={isSubmitting}
                className="w-full rounded-full text-slate-800 text-xl h-16 bg-white hover:text-slate-100 hover:bg-black"
              >
                SUBMIT
              </Button>
            )}

            <div className="grid grid-cols-1 mt-10">
              <div className="flex justify-end sm:flex-row xs:flex-col text-center">
                <Link
                  to={RESET_PATH}
                  className="text-slate-800 px-3 py-1 ml-2 rounded-full border-[1px] border-slate-400 hover:border hover:border-slate-600 xs:my-1"
                >
                  Forgot Password
                </Link>{' '}
                <Link
                  to={SIGN_UP}
                  className="text-slate-100 px-3 py-1 ml-2 rounded-full bg-slate-800 border-[1px] xs:my-1"
                >
                  Sign Up
                </Link>{' '}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </AuthWrapper>
  );
};

export default SignIn;
